import { execHaloCmdWeb } from "@arx-research/libhalo";
import { updateInventoryItem, checkPublicKey } from '../utils/BubbleAPIs';

export async function encodeItem(uid) {
    let command = {
      name: "sign",
      keyNo: 1,
      message: "010204",
    };

    try {
      const res = await execHaloCmdWeb(command);
      const check = await checkPublicKey(res.publicKey);
      if (!check) {
        const response = await updateInventoryItem(uid, res.publicKey, res.signature.ether);
        console.log('Success:', response);
        return {statusText: `Success! Encoded with Public Key: ${res.publicKey}`, btnVisible: true};
      } else {
        console.log('statusText', `Failed! Public Key: ${res.publicKey} already used!`)
        return {statusText: `Failed! Public Key: ${res.publicKey} already used!`, btnVisible: false};
      }

    } catch (e) {
      return {statusText: `${e.name}: Failed to execute scanner.`, btnVisible: false};
    }
  }