// Utilities.js

// Environment variables
const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = process.env.REACT_APP_BUBBLE_TOKEN;

// Headers for fetch requests
const headers = {
    'accept': 'application/json',
    'content-type': 'application/json',
    'authorization': `Bearer ${TOKEN}`
};

// Fetch data from API
async function fetchData(url, method, body = null) {
    try {
        const options = {
            method,
            headers,
            body: body ? JSON.stringify(body) : null
        };
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
}


// Get inventory items
export async function getInventoryItems(uid) {
    const payload = JSON.stringify([
        { 'key': 'Product', 'constraint_type': 'equals', 'value': uid },
        { 'key': 'publicKey', 'constraint_type': 'is_empty' }
    ]);
    const url = `${API_URL}InventoryItem?constraints=${payload}`;
    const data = await fetchData(url, 'GET');
    console.log('Data:', data);
    return data?.response?.results;
}

// Update inventory item
export async function updateInventoryItem(uid, public_key, signature) {
    const url = `${API_URL}InventoryItem/${uid}`;
    const body = { publicKey: public_key, signature: signature };
    
    return await fetchData(url, 'PATCH', body);
}

// Check account public key
export async function checkPublicKey(public_key) {
    const payload = JSON.stringify([
        { 'key': 'publicKey', 'constraint_type': 'equals', 'value': public_key }
    ]);
    const url = `${API_URL}InventoryItem?constraints=${payload}`;
    const data = await fetchData(url, 'GET');
    if (data?.response?.results?.length > 0) {
        return true;
    } else {
        return false;
    };
}

// Check all public key
export async function checkScanned(public_key) {
    const payload = JSON.stringify([
        { 'key': 'publicKey', 'constraint_type': 'equals', 'value': public_key }
    ]);
    const url = `${API_URL}ARXChips?constraints=${payload}`;
    const data = await fetchData(url, 'GET');
    if (data?.response?.results?.length > 0) {
        return true;
    } else {
        return false;
    };
}

// Get account
export async function getAccount(account) {
    const url = `${API_URL}Account/${account}`;
    const data = await fetchData(url, 'GET');
    return data?.response?.result;
}

// Get accounts
export async function getAccounts() {
    const url = `${API_URL}Account`;
    const data = await fetchData(url, 'GET');
    return data?.response?.results;
}

// Create chip records
export async function createChipRecords(data) {
    const url = `${API_URL}ARXChips/bulk`;

    const headers = {
        'Accept': 'text/plain',
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${TOKEN}`
    };

    const dataText = data.map(item => JSON.stringify(item)).join('\n');

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: dataText
        });

        console.log('Response:', response);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Success:', result);
        return response.status;
    } catch (error) {
        console.error('Error:', error);
        return error.status;
    }
}