import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getInventoryItems } from '../utils/BubbleAPIs';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [uid, setUid] = useState(null);
    const [items, setItems] = useState([]);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/encode') {
            const urlParams = new URLSearchParams(location.search);
            const uid = urlParams.get('uid');

            if (uid) {
                setUid(uid);
            } else {
                console.error('UID is not available');
                window.location.href = 'https://app.toinfinfty.com/'; // Redirect to example.com if uid is false
            }
        }
    }, [location]);

    useEffect(() => {
        if (uid && location.pathname === '/encode') {
            getInventoryItems(uid)
                .then(items => {
                    setItems(items);
                })
                .catch(error => {
                    console.error('Error fetching inventory items:', error);
                    window.location.href = 'https://app.toinfinfty.com/'; // Redirect to example.com on error
                });
        }
    }, [uid, location]);

    return (
        <ProductContext.Provider value={{ items, setItems }}>
            {children}
        </ProductContext.Provider>
    );
};