import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAccounts, createChipRecords } from '../../utils/BubbleAPIs';
import '../../App.css';
import './Ntag424.css';

const Scan = () => {
    const [scannedItems, setScannedItems] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [alert, setAlert] = useState('');
    const [success, setSuccess] = useState('');
    
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const account_id = urlParams.get('account_id');

    const handleUpload = async () => {
        const items = scannedItems.map(item => ({ ...item, account: account_id }));

        try {
            const code = await createChipRecords(items);
            if (code === 200) {
                setSuccess('Successfully uploaded chips');
                setScannedItems([]);
            } else {
                setAlert('Error uploading chips');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const fetchAccounts = async () => {
            const response = await getAccounts();
            const accounts = response.map(account => ({ id: account._id, name: account.Name }));
            setAccounts(accounts.sort((a, b) => a.name.localeCompare(b.name)));
        };
        fetchAccounts();
    }, []);


    const handleScan = async () => {
        try {
            console.log('Scanning NTAG 424 Chip');
        } catch (error) {
            console.error('Error adding NTAG 424 Chip', error);
        }
    };

    return (
        <div className='ChipScanner'>
                {alert && <p className='alert error'>{alert}</p>}
                {success && <p className='alert success'>{success}</p>}
            <div className='header'>
                <h1>NTAG 424 Chip Scanner</h1>
            </div>
            <table>
                <thead>
                    <tr className='table-header'>
                        <th className='table-title chip'>UID</th>
                    </tr>
                </thead>
                <tbody>
                    {scannedItems.map(item => (
                        <tr className='table-row' key={item.publicKey}>
                            <td className='table-value chip'>{item.publicKey}</td>
                        </tr>
                    ))}
                </tbody>
                {scannedItems.length === 0 && (
                    <tfoot>
                        <span>No scanned chips</span>
                    </tfoot>
                )}
            </table>
            <div className='footer'>
                <div className='btn-container'>
                    <button className='btn btn-primary' onClick={handleScan}>Scan</button>
                </div>
                {accounts.length > 0 && (
                    <div className='btn-container'>
                        <button className='btn btn-secondary' onClick={handleUpload}>Bulk Upload</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Scan;