import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faShirt } from "@fortawesome/free-solid-svg-icons";
import './Components.css';

const navItems = [
    { name: 'Arx', route: 'arx', icon: faGift },
    // { name: 'NTAG 424', route: 'ntag424', icon: faShirt},
    { name: 'Encode', route: 'encode', icon: faShirt },
    ];

function NavBar() {
  const [selected, setSelected] = useState(null);
  const location = useLocation();

  // Set selected state based on current route and query parameters
  useEffect(() => {
    const currentRoute = location.pathname.substring(1);
    const queryParams = new URLSearchParams(location.search);
    const param = queryParams.get('param');
    setSelected(`${currentRoute}${param ? `/${param}` : ''}`);
  }, [location]);

  return (
    <div className="Navigation">
      <div className="Nav-Row">
        {navItems.map((item) => (
          <Link 
            key={item.name}
            className={`Nav-Item ${selected === item.route ? 'selected' : ''}`} 
            to={`/${item.route}${location.search}`} 
            onClick={() => {
              const queryParams = new URLSearchParams(location.search);
              const param = queryParams.get('param');
              setSelected(`${item.route}${param ? `/${param}` : ''}`);
            }}
          >
            <FontAwesomeIcon 
                icon={item.icon} 
                size="xl" 
                color={selected === item.route ? '#5a189a' : '#000'} 
            />
            <span 
              className={`Nav-Text ${selected === item.route ? 'selected' : ''}`} 
            >
              {item.name}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default NavBar;