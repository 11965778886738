import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './pages/home/Home';
import Encode from './pages/encode/Encode';
import Arx from './pages/arx/Arx';
import Ntag424 from './pages/ntag424/Ntag424';
import { ProductProvider } from './components/AppContext';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
        <Router>
          <ProductProvider>
            <NavBar />
            <div className='Content'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/encode" element={<Encode />} />
              <Route path="/arx" element={<Arx />} />
              <Route path="/ntag424" element={<Ntag424 />} />
              <Route path="*" element={<Home />} />
            </Routes>
            </div>
          </ProductProvider>
        </Router>
    </div>
  );
}

export default App;