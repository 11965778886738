import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { computeAddress } from 'ethers';
import { execHaloCmdWeb } from "@arx-research/libhalo";
import parseKeysCli from '../../helpers/parseKeysCli';
import { checkScanned, getAccounts, createChipRecords } from '../../utils/BubbleAPIs';
import '../../App.css';
import './Arx.css';

const Scan = () => {
    const [publicKey, setPublicKey] = useState('');
    const [address, setAddress] = useState('');
    const [hash, setHash] = useState('');
    const [edition, setEdition] = useState(0);
    const [scannedItems, setScannedItems] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [alert, setAlert] = useState('');
    const [success, setSuccess] = useState('');
    
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const account_id = urlParams.get('account_id');

    const handleUpload = async () => {
        const items = scannedItems.map(item => ({ ...item, account: account_id }));

        try {
            const code = await createChipRecords(items);
            if (code === 200) {
                setSuccess('Successfully uploaded chips');
                setScannedItems([]);
            } else {
                setAlert('Error uploading chips');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const fetchAccounts = async () => {
            const response = await getAccounts();
            const accounts = response.map(account => ({ id: account._id, name: account.Name }));
            setAccounts(accounts.sort((a, b) => a.name.localeCompare(b.name)));
        };
        fetchAccounts();
    }, []);

    useEffect(() => {
        const addNewItem = async () => {
            const check = await checkScanned(publicKey);
            if (!check) {
                const newItems = { publicKey, address, hash, edition };
                setScannedItems(prevItems => [...prevItems, newItems]);
                setAlert('');
            } else {
                setAlert('Public Key already used!');
            }
        };
        if (publicKey && address && hash) addNewItem();
    }, [publicKey, address, hash, edition]);

    const handleScan = async () => {
        const command = { name: "sign", keyNo: 1, message: "010204" };
        try {
            const res = await execHaloCmdWeb(command);
            const address = computeAddress('0x' + res.publicKey)
            const keys = parseKeysCli(res.publicKey);
            setPublicKey(res.publicKey);
            setAddress(address);
            setHash(keys.primaryPublicKeyHash);
            setEdition(res.signature.raw.v);
        } catch (error) {
            console.error('Error executing Halo command:', error);
        }
    };

    return (
        <div className='ChipScanner'>
                {alert && <p className='alert error'>{alert}</p>}
                {success && <p className='alert success'>{success}</p>}
            <div className='header'>
                <h1>Arx Chip Scanner</h1>
            </div>
            <table>
                <thead>
                    <tr className='table-header'>
                        <th className='table-title chip'>Public Key</th>
                        <th className='table-title chip'>Address</th>
                        <th className='table-title chip'>Hash</th>
                        <th className='table-title chip'>Edition</th>
                    </tr>
                </thead>
                <tbody>
                    {scannedItems.map(item => (
                        <tr className='table-row' key={item.publicKey}>
                            <td className='table-value chip'>{item.publicKey}</td>
                            <td className='table-value chip'>{item.address}</td>
                            <td className='table-value chip'>{item.hash}</td>
                            <td className='table-value chip'>{item.edition}</td>
                        </tr>
                    ))}
                </tbody>
                {scannedItems.length === 0 && (
                    <tfoot>
                        <span>No scanned chips</span>
                    </tfoot>
                )}
            </table>
            <div className='footer'>
                <div className='btn-container'>
                    <button className='btn btn-primary' onClick={handleScan}>Scan</button>
                </div>
                {accounts.length > 0 && (
                    <div className='btn-container'>
                        <button className='btn btn-secondary' onClick={handleUpload}>Bulk Upload</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Scan;