import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import './Home.css';

const Home = () => {
    return (
        <div className='Home'>
            <div className='container'>
                <div className='header'>
                    <h1>Welcome to the Home Page</h1>
                </div>
                <div className='content'>
                    <img src={logo} alt="Logo" />
                    <form className='login'>
                        <input type='text' placeholder='Username' />
                        <input type='password' placeholder='Password' />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Home;
