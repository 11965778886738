import React, { useContext } from 'react';
import { ProductContext } from '../../components/AppContext';
import { encodeItem } from '../../components/ScanChip';
import '../../App.css';
import './Encode.css';

const Encode = () => {
  const { items, setItems } = useContext(ProductContext);

  // Handle button click
  async function btnClick(uid) {
    const {statusText, btnVisible} = await encodeItem(uid);
    const newItems = items.map((item) =>
        item._id === uid ? { ...item, status: "Scanned", statusVisible: true, statusText: statusText, scanned: btnVisible } : item
      );
    setItems(newItems);
  };

  return (
    <div className="Inventory-Container">
      <div className="Inventory-Header">
        <h3>MY INVENTORY</h3>
        <span className='alert-text'>Tap Scan and hold HaLo tag to your NFC reader</span>
      </div>
        <table>
          <thead>
            <tr className='table-header'>
              <th className='table-header-title'>UID</th>
              <th className='table-header-title action'>Action</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <React.Fragment key={index}>
                <tr className='table-row'>
                  <td className='table-name'>{item.UID}</td>
                  <td className='table-button'>
                    {!item.scanned && (
                      <button className='btn' onClick={() => btnClick(item._id)}>SCAN</button>
                    )}
                  </td>
                </tr>
                {item.statusVisible && (
                  <tr>
                    <td colSpan="2" className='status_text'>
                      {item.statusText}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
          <tfoot>
            <span> Total items: {items.length}</span>
          </tfoot>
        </table>
    </div>
  );
}

export default Encode;